@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl-SemiBold"),
    url("assets/fonts/SuisseIntl-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl-Medium"),
  url("assets/fonts/SuisseIntl-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl-Light"),
  url("assets/fonts/SuisseIntl-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl-Regular"),
    url("assets/fonts/SuisseIntl-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: block;
}

/*
  font weight 600 - semi bold
  font weight 400/normal - regular
*/

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "SuisseIntl", serif;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.modal-close {
  opacity: 0;
  visibility: hidden;
}

.modal-open {
  opacity: 1;
  visibility: visible;
}

.no-scroll {
  overflow: hidden !important;
}
